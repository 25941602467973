<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="content">
            <video class="video" :src="info.vedioUrl" controls ></video>

            <div class="content-right">
                <div>{{ info.title }}</div>
                <div>
                    <img src="@/assets/images/Frame-21.png" alt="" />
                    {{ info.createTime }}
                </div>
                <div>
                    <img src="@/assets/images/Frame-38.png" alt="" />
                    {{ info.playerCount || 0 }}
                </div>
                <div class="line"></div>
                <div>视频简介</div>
                <div style='white-space: pre-wrap;' class="vHtml" v-html="info.vedioDesc ? info.vedioDesc.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
            </div>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import { getBrightspaceDetails } from '@/api';
export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            nav: [
                {
                    title: '首页',
                    to: {
                        path: '/',
                    },
                },
                {
                    title: '璀璨空间',
                },
            ],
            info: {},
        };
    },
    props: {},
    // 方法集合
    methods: {
        getBrightspaceDetails() {
            getBrightspaceDetails(this.$route.query.id).then((res) => {
                console.log(res);
                this.info = res.data;
            });
        },
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {},
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        this.getBrightspaceDetails();
    },
    // 生命周期 - 挂载之前
    beforeMount() {},
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {},
    // 生命周期 - 更新之前
    beforeUpdate() {},
    // 生命周期 - 更新之后
    updated() {},
    // 生命周期 - 销毁之前
    beforeDestroy() {},
    // 生命周期 - 销毁完成
    destroyed() {},
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {},
    //离开的时候触发
    deactivated() {},
};
</script>

<style lang="less" scoped>
.box {
    .content {
        display: flex;

        .video {
            width: 900px;
            height: 506.25px;
        }

        &-right {
            flex: 1;
            padding: 12px 16px;

            div:nth-child(1) {
                font-weight: 700;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 30px;
            }

            div:nth-child(2),
            div:nth-child(3) {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                }
            }

            .line {
                height: 1px;
                background: #ebebeb;
            }

            div:nth-child(5) {
                margin: 12px 0 16px 0;
                font-weight: 700;
                font-size: 15px;
                color: #333333;
            }

            div:nth-child(6) {
                font-weight: 400;
                font-size: 12px;
                color: #666666;
            }
        }
    }
}
</style>
